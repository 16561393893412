<template>

  <div class="row maxDiv">
    
    <div class = "col s12 orange marginTop">
      <h5 class = "white-text center-align">Nueva Disputa</h5>
    </div>

    <DisputeGuide />
 
    <div class="col s12 grey darken-2" style="padding: 15px;">
      
      <form @submit.prevent="openDispute">
        
        <div class = 'col s12 center-align input-field'>
          
          <textarea 
            id="disputeMessage" 
            v-model='message' 
            class='materialize-textarea' 
            data-length='500'
          ></textarea>

          <label for='disputeMessage'><i class='fas fa-pen'></i> Mensaje</label>
        
          <div class="input-field">
            <label for="disputeAgree">
                <input type="checkbox" id="disputeAgree" @click="isDisabled = !isDisabled">
                <span class = 'white-text'>Entiendo el proceso de disputa</span>
            </label>
          </div>
         
        </div>

        <div class="col s12 center bottomDiv">

          <Loader v-if="isLoading" />
          
          <Error v-if="error && !isLoading" :warning="error"/>

          <p class="bottonDiv">
            <button class = "btn orange white-text green-text" :class="{disabled: !isDisabled}">
              <i class = 'fas fa-check'></i>
            </button>
          </p>

        </div>
      
      </form>
    
    </div>

  </div>

</template>

<script>
import DisputeGuide from './DisputeGuide.vue'
import Loader from '@/components/UI/Loader'
import Error from '@/components/UI/Error'
import { ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { post } from '@/composables/httpCalls';
import { socket } from '@/composables/socket';
import useBoilerplate from '@/composables/useBoilerplate';

export default {
  
  props: ['escrow'],
  components: { Loader, Error, DisputeGuide },
  emits: ['success'],
  
  setup(props, context){

    const { store, isLoading, error } = useBoilerplate(false);

    const message = ref('');
    const isDisabled = ref(false);
    
    const openDispute = async () => {
      
      error.value = false;
      isLoading.value = true;
                
      const url = "/api/create-dispute";

      const vars = {
          id: props.escrow,
          message: message.value
      }
                
      const res = await post(url, vars);

      if(res.status == 200){
          const data = await res.json();
          socket.emit('new escrow stage', data.escrow);
          context.emit('success');
      }else if(res.status == 412){
          store.state.logUserOut();
          router.push({name: 'Auth'});
      }else if(res.status == 404){
          isLoading.value = false;
          error.value = "Usted No Puede Abrir Esta Disputa";
      }else{
          isLoading.value = false;
          error.value = "No Se Pudo Abrir la Disputa";
      }
    }
    
    onMounted(() => M.AutoInit());

    return { openDispute, isLoading, error, isDisabled, message }
  }

}
</script>

<style scoped>
  textarea{
    color: white !important;
  }
  .bottomDiv{
    padding-top: 35px;
    padding-bottom: 15px;
  }
</style>