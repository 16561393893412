<template>
    <h1 class = "margin">
        <i class="fas fa-fist-raised grey-text"></i>
    </h1>
</template>

<style scoped>
    i{
        font-size: 120px;
    }
</style>