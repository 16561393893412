<template>
  
  <div class="container" v-if="isSuccess" >
    <div class="row">
      <Success title="Disputa Abierta" @goBack="back2Escrow">
        <DisputeSuccess :escrow="id"/>
      </Success>
    </div>
  </div>
  
  <div v-else class = "container">
      <NewDisputeForm @success="isSuccess=true" :escrow="id"/>
  </div>

</template>

<script>

import { ref } from '@vue/runtime-core'
import NewDisputeForm from '@/components/Dispute/NewDisputeForm';
import DisputeSuccess from '@/components/Dispute/DisputeSuccess';
import Success from '@/components/UI/Success';
import useBoilerplate from '@/composables/useBoilerplate';

export default {
  
  components: { Success, DisputeSuccess, NewDisputeForm },
  props: ['id'],  
  setup(props){

    const { router, isSuccess } = useBoilerplate(false);
    
    const back2Escrow = () => {
        const destination = {name: "Escrow", params: { id: props.id }}
        router.push(destination);
    }

    return { router, isSuccess, back2Escrow }
  
  }

}

</script>