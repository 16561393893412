<template>
    <div class="col s12 left-align white">
      <div class="col s12 grey lighten-4">
        <p class="grey-text text-darken-4">
          Para abrir una disputa llene el <b class = 'orange-text'>formulario abajo.</b>
        </p>
        <p class="grey-text text-darken-4">
          Abrir una <b>disputa</b> tiene que ser <b class = 'orange-text'>la ultima opción</b> para resolver un problema entre el comprador y el vendedor. Muchas vezes es más fácil tratar de llegar a un acuerdo entre las partes sencillamente hablando del asunto por chat.
        </p>
        <p class="grey-text text-darken-4">
          Si desea abrir una disputa, espliquenos la situación en el formulario abajo. QBita revisarà en asunto y contactarà por chat ambas partes para recoger más informaciones si eso es necesario.
        </p>
        <p class="grey-text text-darken-4">
          <b>Es muy importante que Usted estè pendiente del chat de QBita</b>, por que no enviar a tiempo las informaciones y las evidencias que se le soliciten puede resultar en en resolución de la disputa en favor de la otra parte.
        </p>
        <p class="grey-text text-darken-4">
          Para maximizar sus posibilidades de éxito, es importante que Usted <b class = 'orange-text'>tenga listas todas las evidencias a soporte de su posicion en la disputa.</b>   
        </p>
      </div>
    </div>
</template>
